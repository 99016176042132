<template>
  <div id="app">
    <Navigation/>
  </div>
</template>

<script>
import Navigation from './components/index.vue'
export default {
  name: 'App',
  components: {
    Navigation,
  }
}
</script>

<style>
</style>
