<template>
  <div>
    <a-layout>
      <a-layout-header class="header-cover">
        <div class="img-cover">
          <img src="../assets/logo.png" />
        </div>
      </a-layout-header>
      <a-layout-content>
        <a-row>
          <a-col :span="3" />
          <a-col :span="18"
            ><div class="context-container">
              <a-row style="margin: 80px 0px">
                <div v-for="(item, index) in addressDataJson" :key="index">
                  <a-col
                    :span="6"
                    class="context-cover"
                    @contextmenu.prevent.native="showModal(item)"
                    @click="clicktest(item)"
                  >
                    <div class="context">
                      <a-row>
                        <a-col :span="5" class="icon-cover">
                          <a-icon
                            :type="item.Address.Icon"
                            :style="{
                              color: colors[Math.floor(Math.random() * 10)],
                            }"
                          />
                        </a-col>
                        <a-col :span="19">
                          <h2>{{ item.Address.AddressName }}</h2>
                          <p>{{ item.Address.AddressDescription }}</p>
                        </a-col>
                      </a-row>
                    </div>
                  </a-col>
                </div>
              </a-row>
            </div></a-col
          >
          <a-col :span="3" />
        </a-row>
      </a-layout-content>
    </a-layout>
    <a-modal
      width="35%"
      :visible="modelVisible"
      title="查询账号密码"
      @ok="handleOk"
      :destroyOnClose="true"
      :closable="true"
      :footer="null"
      @cancel="handleOk"
    >
      <a-card title="输入用户名密码验证">
        <a-form
          :model="checkForm"
          name="basic"
          :label-col="{ span: 8 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-form-item label="用户名" name="username">
            <a-input v-model="checkForm.username" />
          </a-form-item>

          <a-form-item label="密码" name="password">
            <a-input-password v-model="checkForm.password" />
          </a-form-item>
          <a-form-item :wrapper-col="{ offset: 8, span: 16 }">
            <a-button @click="checkPassWord" type="primary" html-type="submit" :loading="loading"
              >Submit</a-button
            >
          </a-form-item>
        </a-form>
      </a-card>
      <a-card
        title="用户名密码查询结果"
        v-show="form.username && form.password"
        style="margin-top: 15px"
      >
        <a-form
          :model="form"
          name="basic"
          :label-col="{ span: 8 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-form-item label="Username" name="username">
            <a-input v-model="form.username" />
          </a-form-item>
          <a-form-item label="Username" name="username">
            <a-input v-model="form.password" />
          </a-form-item>
        </a-form>
      </a-card>
    </a-modal>
  </div>
</template>
<script>
import { decrypt ,encrypt} from "../utils/CryptoJSUtil";
import { GetAddressifo } from "../api/user";
export default {
  data() {
    return {
      colors: [
        "#c1232b",
        "#27727b",
        "#e87c25",
        "#fe8463",
        "#9bca63",
        "#f3a43b",
        "#60c0dd",
        "#c1232b",
        "#27727b",
        "#001852",
      ],
      addressDataJson: [],
      modelVisible: false,
      form: {
        username: null,
        password: null,
      },
      checkForm: {
        username: null,
        password: null,
      },
      selectItem: null,
      loading:false
    };
  },
  beforeCreate() {
    // document.onkeydown = () => {
    //   //禁用F12
    //   if (window.event && window.event.keyCode == 123) {
    //     return false;
    //     //禁用ctrl+shift+i,
    //   } else if (
    //     window.event.ctrlKey &&
    //     window.event.shiftKey &&
    //     window.event.keyCode == 73
    //   ) {
    //     return false;
    //     //屏蔽Shift+F10
    //   } else if (window.event.shiftKey && window.event.keyCode == 121) {
    //     return false;
    //   }
    // };
    // document.oncontextmenu = new Function("event.returnValue=false");
  },
  mounted() {
    this.addressDataJson = JSON.parse(sessionStorage.getItem("address"));
  },
  methods: {
    clicktest(item) {
     // window.location.href = item.Address.AddressUrl;
      window.open(item.Address.AddressUrl);
    },
    showModal(item) {
      if (item.LoginName && item.Password) {
        this.selectItem = item;
        this.modelVisible = true;
      }
    },
    handleOk() {
      this.modelVisible = false;
    },
    async checkPassWord() {
      const res_Permission = await GetAddressifo({
        UserName: this.checkForm.username,
        PassWord: encrypt(this.checkForm.password),
        AddressId: this.selectItem.AddressId,
      });

      if (res_Permission.Success) {
        this.form.username = decrypt(res_Permission.Data.LoginName);
        this.form.password = decrypt(res_Permission.Data.Password);
      }
    },
  },
};
</script>
<style>
.header-cover {
  padding: 0px !important;
  background: #010822 !important;
}
.img-cover {
  overflow: hidden;
  border-color: transparent;
  border-width: 0px;
  position: relative;
  width: 159px;
  height: 62px;
}
.header-cover img {
  width: auto;
  height: 169px;
  top: -57px;
  left: -53px;
  position: relative;
  display: inline;
}
.context-cover {
  padding: 50px !important;
}
.context :hover {
  background-color: #e8e8e9;
}
.icon-cover ::before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 120px;
}
.context {
  padding: 20px !important;
  height: 120px;
}
.context i {
  font-size: 55px;
}
.context h2 {
  font-size: 26px;
  font-weight: 700;
}
</style>